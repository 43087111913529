const BODY = document.querySelector('body');
const MENU = document.querySelector('.js-menu-btn');
const MENU_CLOSE = document.querySelector('.js-menu-close');
const HEADER_MENU = document.querySelector('.js-header-menu');
const HEADER_COVER = document.querySelector('.js-header-cover');
const PHONE_INPUT = document.querySelector('.js-phone-input');
const NAME_INPUT = document.querySelector('.js-name-input');
const EMAIL_INPUT = document.querySelector('.js-email-input');
const BOOK_NOW = document.querySelector('.js-book-now');
const CTA_FORM = document.querySelector('.js-cta-form');
const SUBMIT_BTN = document.querySelector('.js-submit-btn');
const MSG_INPUT = document.querySelector('.js-message-input');

// var pickupYes = document.querySelector('.js-pickup-yes');
// var pickupNo = document.querySelector('.js-pickup-no');
// var container = document.querySelector('.js-distance');

// const toggleContainer = () => {
//   if (pickupYes?.checked) {
//     container.style.display = 'block';
//   } else {
//     container.style.display = 'none';
//   }
// }

// // Initial toggle
// toggleContainer();

// pickupYes.addEventListener('change', toggleContainer);
// pickupNo.addEventListener('change', toggleContainer);

const formatPhoneNumber = () => {
  let phoneNumber = PHONE_INPUT.value.replace(/\D/g, "");

  if (phoneNumber.length === 0) {
    PHONE_INPUT.value = "";
    return;
  }

  if (phoneNumber.length > 10) {
    phoneNumber = phoneNumber.substring(0, 10);
  }

  if (phoneNumber.length > 3) {
    phoneNumber = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3)}`;
  }

  if (phoneNumber.length > 8) {
    phoneNumber = `${phoneNumber.substring(0, 9)}-${phoneNumber.substring(9)}`;
  }

  PHONE_INPUT.value = phoneNumber;
}

const handleBackspace = (event) => {
  if (event.key === "Backspace") {
    event.preventDefault();
    PHONE_INPUT.value = PHONE_INPUT.value.replace(/\D/g, "").slice(0, -1);
    formatPhoneNumber();
  }
}

const handlePaste = (event) => {
  event.preventDefault();
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData("text");
  PHONE_INPUT.value = pastedData.replace(/\D/g, "");
  formatPhoneNumber();
}

MENU.addEventListener('click', () => {
    BODY.classList.toggle('u-no-scroll');
    BODY.classList.toggle('body--reveal-menu');
    const scrollHeight = document.documentElement.scrollTop;
    HEADER_MENU.style.transform = `translate(7em, ${scrollHeight}px)`;
});

MENU_CLOSE.addEventListener('click', () => {
    BODY.classList.toggle('u-no-scroll');
    BODY.classList.toggle('body--reveal-menu');
});

HEADER_COVER.addEventListener('click', () => {
    BODY.classList.toggle('u-no-scroll');
    BODY.classList.toggle('body--reveal-menu');
});

PHONE_INPUT.addEventListener("input", formatPhoneNumber);
PHONE_INPUT.addEventListener("keydown", handleBackspace);
PHONE_INPUT.addEventListener("paste", handlePaste);

BOOK_NOW.addEventListener('click', () => {
  CTA_FORM.scrollIntoView({ behavior: 'smooth' });
  CTA_FORM.classList.add('cta-form--active');
  BOOK_NOW.style = 'display: none';
});

const checkFormValidity = () => {
  const isNameValid = NAME_INPUT.checkValidity();
  const isEmailValid = EMAIL_INPUT.checkValidity();
  const isPhoneValid = PHONE_INPUT.checkValidity();
  const isMsgValid = MSG_INPUT.value.length > 0;

  if (isNameValid && isEmailValid && isPhoneValid && isMsgValid) {
    SUBMIT_BTN.disabled = false; // Enable the submit button
  } else {
    SUBMIT_BTN.disabled = true; // Disable the submit button
  }
}

NAME_INPUT.addEventListener('input', checkFormValidity);
EMAIL_INPUT.addEventListener('input', checkFormValidity);
PHONE_INPUT.addEventListener('input', checkFormValidity);
MSG_INPUT.addEventListener('input', checkFormValidity);

// function changeBackground(gender) {
//     document.body.setAttribute("data-gender", gender);
// }

document.querySelector(".bio__show-more").addEventListener("click", function(event) {
  event.preventDefault();
  document.querySelector(".bio__more").classList.remove("u-none");
  document.querySelector(".bio__show-more").classList.add("u-none");
});

